import {
  AfterContentInit,
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DefaultLangChangeEvent, TranslateService} from '@ngx-translate/core';
import {
  EcaseHttpService,
  ECaseNumberFormatterPipe,
  ECaseSnackBarService,
  ECaseUtils,
  GeneralDialogComponent,
  LoginAuthenticationService,
  ParametersService,
  PendingChangesGuard,
  ProjectService,
  RefreshDataService,
  SectionBreadCrumbService,
  TitleService
} from 'synto-common';
import * as _ from 'lodash';
import {Subscription} from 'rxjs/internal/Subscription';
import {webSocket} from 'rxjs/webSocket';
import {HttpResponse} from '@angular/common/http';
import {ValidationDialogComponent} from '../validation-dialog/ecase-validation-dialog.component';
import {DialogComponent} from '../../formbuilder/dialog/dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {DialogService} from './dialog.service';

import moment from 'moment';

export interface InEvent {
  id: string;
  name: string;
  username: string;
  eventType: string;
  time: number;
}

export interface InEvent {
  id: string;
  formData: any;
  name: string;
  username: string;
  eventType: string;
  time: number;
}

@Component({selector: 'app-form', templateUrl: './form.component.html', styleUrls: ['./form.component.scss']})
export class FormComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit, DoCheck {
  @Input() globalReadOnlyMode;
  @Input() showSaveNavBar;
  @Input() saveUrl;
  @Input() publishSaveUrl;
  @Input() formID;
  @Input() formData;
  @Input() params;
  @Input() lovs;
  @Input() formJSON;
  @Input() selectedSectionIndex;
  @Input() showHeaderAndSectionNavBar;
  @Input() keysTobeRemoved;
  @Input() formDataUrl;
  @Input() actions;
  @Input() isUseVariablesFromProjectService;
  @Input() isFormbuilderModeEnabled;
  @Input() isNoPaddingOnFormContainerEnabled;
  @Input() isFormRevisionMode: boolean;
  @Input() isFormRevisionSyncPending: boolean;
  @Output() dataChanged = new EventEmitter<any>();
  @Output() emitOutputEvent = new EventEmitter<any>();
  @Output() saveOutputEvent = new EventEmitter<any>();
  inputsArray = ['showSaveNavBar', 'globalReadOnlyMode', 'saveUrl', 'formID', 'formData', 'params',
    'lovs', 'formJSON', 'selectedSectionIndex', 'showHeaderAndSectionNavBar', 'keysTobeRemoved', 'formDataUrl', 'actions'];
  globalComponent;
  showSectionsNavBar;
  exportMenu = ['ecase.common.section', 'ecase.common.completeForm'];
  lang;
  lastServerAccess;
  conf;
  prj;
  isExecuteCustomJS;
  globalPrj;
  pristineStr = '';
  globalPrjStr = '';
  lastSectionIndex;
  offline = false;
  globalValidation;
  selectedSection;
  offlineId;
  offlineLabel;
  errors;
  sections = [];
  timer;
  previousSection;
  messages;
  emptyPrj;
  sectionSelected: any = {};
  subject;
  interval;
  showLegend = false;
  languageChangeSubscription: Subscription;
  subscription: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  subscription4: Subscription;
  differ: KeyValueDiffer<string, any>;
  isSaveButtonClicked = false;
  isRevisionSaveButtonClicked = false;
  isValidateButtonClicked = false;
  isPrintButtonClicked = false;
  pathname = '';

  selectCancellForMessage;
  constructor(private eCaseHttpService: EcaseHttpService, private eCaseSnackBarService: ECaseSnackBarService, private matDialog: MatDialog,
              private activatedRoute: ActivatedRoute, public pendingChangesGuard: PendingChangesGuard,
              private location: Location, private dialogService: DialogService, private router: Router, private eCaseNumberFormatterPipe: ECaseNumberFormatterPipe,
              private translate: TranslateService, private refreshDataService: RefreshDataService, private projectService: ProjectService,
              private loginAuthenticationService: LoginAuthenticationService, private setSectionBreadcrumb: SectionBreadCrumbService,
              private titleService: TitleService, private parametersService: ParametersService, private differs: KeyValueDiffers) {
    this.pathname = window.location.pathname;
    if (window.location.pathname !== '/') {
      this.showSaveNavBar = (this.showSaveNavBar === undefined) ? true : this.showSaveNavBar;
      this.globalComponent = this;
      this.globalComponent.moment = moment;
      this.globalComponent.cloneDeep = _.cloneDeep;
      this.globalComponent.lodash = _;
      this.globalComponent.parameter = this.parametersService.parameter;
      this.showSectionsNavBar = (this.showSectionsNavBar === undefined) ? true : this.showSectionsNavBar;
      this.lang = this.translate.getDefaultLang();
      for (const variable of this.inputsArray) {
        console.log(variable);
        if ((this.projectService.selectedForm) &&
          ((this.projectService.selectedForm)[variable] ||
            (this.projectService.selectedForm)[variable] === 0 ||
            (this.projectService.selectedForm)[variable] === '' ||
            (this.projectService.selectedForm)[variable] === {} ||
            (this.projectService.selectedForm)[variable] === [])) {
          console.log((this.projectService.selectedForm)[variable]);
          this[variable] = (this.projectService.selectedForm)[variable];
        }
      }
      this.differ = this.differs.find({}).create();
    }
  }

  export(menuItem): void {
    const data: any = {};
    const objToPost: any = {};
    data.actions = ['generatePdf'];
    data.formID = parseInt(this.formID, 10);
    data.doValidation = false;
    data.prj = this.globalComponent.prj;
    data.prj.preferredLanguage = this.projectService.formLangArray.includes(this.translate.getDefaultLang()) ?
      this.translate.getDefaultLang() : this.projectService.formLangArray[0];
    if (menuItem === 'ecase.common.section') {
      data.sectionId = this.sectionSelected.id ? this.sectionSelected.id : this.selectedSection;
    }
    objToPost.data = data;
    objToPost.url = 'save';
    this.isPrintButtonClicked = true;
    this.eCaseHttpService.post('api/callworkflow', objToPost, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe({
      next: (_data: any) => {
        const result: HttpResponse<Blob> = _data;
        ECaseUtils.generateHyperLinkForDownload(result);
        this.isPrintButtonClicked = false;
      }, error: () => {
        this.isPrintButtonClicked = false;
      }
    });
  }

  showSnackBar(status, message): void {
    this.eCaseSnackBarService.showComponent((status ? 'success' : 'failure'), message);
  }

  getCleanedJson(json, isCleanError): any {
    if (json === null || !json) {
      json = {};
    }
    if (json['pdf']) {
      json['pdf'] = {};
    }
    if (isCleanError) {
      if (json['error'] || typeof json['error'] === 'object') {
        json['error'] = {};
      }
      if (json['error_class'] === '' || json['error_class'] === 'error' || typeof json['error'] === 'string') {
        json['error_class'] = '';
      }
    }
    for (const key in json) {
      if (!json.hasOwnProperty(key)) {
        continue;
      }
      if (typeof json[key] === 'object') {
        this.getCleanedJson(json[key], isCleanError);
      }
    }
    return json;
  }

  sectionClicked(sec, i, showSectionChangeDialog): void {
    console.log(this.projectService.selectedForm);
    // this.selectedBphChange.globalShowDetail = false;
    if (location.href.includes(this.projectService.programCompetitionURL)) {
      this.location.replaceState(this.projectService.programCompetitionURL + '/existingProject/' + this.eCaseHttpService.windowId + '/form/' + i);
    }
    this.showSaveNavBar = (this.showSaveNavBar === undefined) ? true : this.showSaveNavBar;
    this.globalComponent.showSaveNavBar = this.showSaveNavBar;
    this.lastServerAccess = (new Date()).getTime();
    const globalComponent: any = this;
    const globalConf = this.conf;
    globalComponent.semaphoreAutosave = false;
    const pristine = globalComponent.pristineJson;
    this.getCleanedJson(pristine, true);
    const globalPrjLocal = _.cloneDeep(globalComponent.prj);
    this.getCleanedJson(globalPrjLocal, true);
    this.pristineStr = JSON.stringify(pristine);
    this.globalPrjStr = JSON.stringify(globalPrjLocal);
    if (this.pristineStr !== this.globalPrjStr) {
      if (!globalConf.messages.changeSectionMessage) {
        this.translate.langs.forEach((lang) => {
          if (lang && lang !== 'undefined') {
            globalConf.messages.changeSectionMessage[lang] = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.unsavedsectionchangealerttext', lang);
          }
        });
      }
      if (showSectionChangeDialog && !this.pendingChangesGuard.isPristine) {
        const save = window.confirm(globalConf.messages.changeSectionMessage[globalComponent.lang] || globalConf.messages.changeSectionMessage['fr']);
        if (save) {
          this.postSectionClicked(sec, i);
          this.selectCancellForMessage = false;
        }
        else {
          this.selectCancellForMessage = true;

        }
      } else {

        this.postSectionClicked(sec, i);
      }
    } else {
      this.postSectionClicked(sec, i);
    }
    for (let j = 0; j < globalComponent.sections.length; j++) {
      if (!((globalComponent.sections[j]).hide)) {
        globalComponent.lastSectionIndex = j;
      }
      if (j === i ) {
        this.sectionSelected = globalComponent.sections[j];
        if(!this.selectCancellForMessage){
          this.sectionSelected.index = j;
        }
        else{
          this.sectionSelected.index = globalComponent.selectedSectionIndex;
        }

      }

    }
    this.lastSectionIndex = globalComponent.lastSectionIndex;
    // this.selectedBphChange.globalShowDetail = false;
    console.log(this.lastSectionIndex);
  }

  iterate(obj): void {
    const globalComponent: any = this;
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (property === 'error') {
          obj[property] = {};
        } else if (typeof obj[property] === 'object') {
          this.iterate(obj[property]);
        }
      }
    }
  }

  postSectionClicked(sec, selectedSectionIndex): void {
    // this.showSaveAndContinue = false;
    const globalComponent: any = this;
    globalComponent.indexSelected = selectedSectionIndex;
    const globalConf = this.conf;
    this.globalValidation = false;
    globalComponent.hideSaveButtons = false;
    globalComponent.prj.upload_to_be_deleted = [];
    if (!this.offline) {
      const params: any = {};
      params.projectId = -1;
      params.formId = parseInt(this.formID, 10);
      params.teamMemberId = -1;
      params.isSuperVisor = false;
      if (this.projectService.selectedForm) {
        if (!this.projectService.selectedForm.dataForFields) {
          this.projectService.selectedForm.dataForFields = {};
          const arr = [];
          arr.push(this.projectService.programCompetitionID);
          this.projectService.selectedForm.dataForFields.competitionIds = arr;
        }
        if (Array.isArray(this.projectService.selectedForm.dataForFields.competitionIds)) {
          params.competitions = this.projectService.selectedForm.dataForFields.competitionIds;
        } else {
          const arr = [];
          arr.push(this.projectService.selectedForm.dataForFields.competitionIds);
          params.competitions = arr;
        }
        params.competitions = params.competitions ? params.competitions.filter(e => !!e) : [];
        if (this.projectService.selectedForm.userId) {
          params.userId = this.projectService.selectedForm.userId;
        }
        if (this.projectService.selectedForm.crmPersonId) {
          params.crmPersonId = this.projectService.selectedForm.crmPersonId;
        }
        if (this.projectService.selectedForm.crmOrganizationId) {
          params.crmOrganizationId = this.projectService.selectedForm.crmOrganizationId;
        }
      }

      params.isFormForConsent = this.projectService.isFormForConsent;
      params.caseJsonDataRepositoryId = globalComponent.prj.caseJsonDataRepositoryId ? globalComponent.prj.caseJsonDataRepositoryId : this.prj.caseJsonDataRepositoryId;
      params.caseApplicationId = globalComponent.prj.caseApplicationId ? globalComponent.prj.caseApplicationId : this.prj.caseApplicationId;
      console.log(params);
      console.log(this.saveUrl);
      if (this.formDataUrl && params.caseJsonDataRepositoryId && this.previousSection.index !== selectedSectionIndex) {
        if (this.formDataUrl.trim() === '') {
          try {
            const isFirstTime = false;
            const globalPrj = globalComponent.prj;
            console.log("globalPrj",globalPrj);
            // eslint-disable-next-line no-eval
            eval(sec.integration);
            this.prj = globalPrj;
          } catch (e) {
            console.error(e);
            console.warn('Some error on integration for section ' + sec.id);
          }
          const globalProjectForAction = this.globalPrj;
          if (this.projectService.selectedForm && this.projectService.selectedForm.actions && JSON.stringify(this.actions) === '[]') {
            this.actions = this.projectService.selectedForm.actions;
          }
          globalComponent.executeAllActionInterval = setInterval(() => {
            const globalPrj = globalProjectForAction;
            const oldValues = globalComponent.oldValues;
            const sectionID = globalComponent.selectedSection;
            try {
              // eslint-disable-next-line no-eval
              eval(this.actions[sectionID]);
            } catch (e) {
              console.error(e);
            }
          }, 200);
          sec.selected = true;
          globalComponent.previousSection = sec;
          this.selectedSectionIndex = selectedSectionIndex;
          if (sec) {
            // eslint-disable-next-line no-eval
            eval(sec.actions);
          }
        }
        else {
          params.isFormRevisionMode = !!this.isFormRevisionMode;
          this.eCaseHttpService.post(this.formDataUrl, params).subscribe((response: any) => {
            if (response.status) {
              let globalProject = response.prj;
              console.log('from section changed');
              console.log(globalProject);
              try {
                const scrollBar = document.querySelector('#synto-content-wrapper');
                document.body.style.overflow = 'hidden';
                scrollBar.scrollTop = 0;
              } catch (e) {
                console.error(e);
              }
              globalProject = response.prj;
              this.iterate(globalProject);
              globalProject.title = globalComponent.prj.title;
              globalProject = _.merge(_.cloneDeep(globalComponent.emptyPrj), globalProject);
              globalComponent.sections.forEach((section, i) => {
                globalProject[globalComponent.sections[i].id].hide = !!(globalComponent.sections[i]).hide;
              });
              const dummyPrj = _.merge(_.cloneDeep(globalComponent.emptyPrj), response.prj);
              globalComponent.prj[sec.id] = dummyPrj[sec.id];
              globalComponent.pristineJson = _.cloneDeep(globalComponent.prj);
              globalComponent.prj = globalProject;
              globalComponent.conf = this.conf;
              globalComponent.http = this.eCaseHttpService;
              globalProject = globalComponent.prj;
              globalComponent.pristineJson = _.cloneDeep(globalProject);
              globalComponent.previousSection.selected = false;
              const prevSection = _.cloneDeep(globalComponent.previousSection);
              globalComponent.selectedSection = sec.id;
              try {
                const isFirstTime = false;
                const globalPrj = globalProject;
                // eslint-disable-next-line no-eval
                eval(sec.integration);
                this.prj = globalPrj;
              } catch (e) {
                console.error(e);
                console.warn('Some error on integration for section ' + sec.id);
              }
              const globalProjectForAction = globalProject;
              if (this.projectService.selectedForm && this.projectService.selectedForm.actions && JSON.stringify(this.actions) === '[]') {
                this.actions = this.projectService.selectedForm.actions;
              }
              globalComponent.executeAllActionInterval = setInterval(() => {
                const globalPrj = globalProjectForAction;
                const oldValues = globalComponent.oldValues;
                const sectionID = globalComponent.selectedSection;
                try {
                  // eslint-disable-next-line no-eval
                  eval(this.actions[sectionID]);
                } catch (e) {
                  console.error(e);
                }
              }, 200);
              sec.selected = true;
              globalComponent.previousSection = sec;
              this.selectedSectionIndex = selectedSectionIndex;
              if (sec) {
                // eslint-disable-next-line no-eval
                eval(sec.actions);
              }
              console.log(' cliiiicked !!!!!!!!');
              console.log(this.lastSectionIndex);
              console.log(globalComponent);
              for (const blockName in (globalComponent.prj[sec.id])) {
                if ((globalComponent.prj[sec.id]).hasOwnProperty(blockName)
                  && typeof ((globalComponent.prj[sec.id])[blockName]) !== 'string'
                  && typeof ((globalComponent.prj[sec.id])[blockName]) !== 'number'
                  && typeof ((globalComponent.prj[sec.id])[blockName]) !== 'boolean') {
                  if (((globalComponent.prj[sec.id])[blockName])) {
                    ((globalComponent.prj[sec.id])[blockName]).error = {};
                  }
                }
              }


              setTimeout(() => {
                globalComponent.semaphoreAutosave = true;
                globalComponent.pristineJson = _.cloneDeep(globalComponent.prj);
              }, 200);
              if (prevSection.condition) {
                console.log('i am inside if********************');
                let timer;
                // eslint-disable-next-line no-eval
                eval(prevSection.condition);
              }
            } else {
              this.showSnackBar(false, 'Error occured');
            }
          });
        }
      }
    } else {
      globalComponent.previousSection.selected = false;
      globalComponent.selectedSection = sec.id;
      sec.selected = true;
      globalComponent.previousSection = sec;
      window.location.href = '#' + sec.id;
    }
    if (sec) {
      const globalPrj = this.prj;
      // eslint-disable-next-line no-eval
      eval(sec.actions);
    }
    console.log(sec);
  };

  isSelectedSection(idSec): boolean {
    return idSec === this.selectedSection;
  }

  toggleSectionNavBar(): void {
    this.showSectionsNavBar = !this.showSectionsNavBar;
  }

  toggleShowSaveNavBar(event): void {
    if (this.showSaveNavBar) {
      this.showSaveNavBar = event;
    } else {
      this.showSaveNavBar = event;
    }
  }

  saveFormFromComponent(): void {
    this.save('Save');
  }

  toggleLegend(showLegend): void {
    this.globalComponent.showLegend = showLegend;
    const offset = (el): any => {
      const rect = el.getBoundingClientRect();
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      console.log(scrollTop);
      console.log(rect.top);
      return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
    };
    const div = document.querySelector('#legendBox');
    const divOffset = offset(div);
    this.globalComponent.legendTop = (498.29864501953125 + 130) + 'px';
  }

  changeValue(newLanguage): void {
    this.lang = newLanguage;
  }


  getLabel18nValue(currentValue, lang): string {
    if (currentValue.value && currentValue.value.label) {
      return currentValue.value.label[this.lang];
    } else {
      return '';
    }
  }

  getTranslatedValue(key): string {
    return ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.lang);
  }

  trackByFn(index, item): any {
    return item.value;
  }

  goOffline(demande): void {
    const globalPrj = this.prj;
    const globalConf = this.conf;
    const globalComponent = this;
    if (!this.offline) {
      const msg = globalConf.messages.offlineMessage[globalComponent.lang];
      this.showSnackBar(true, msg);
      this.offline = true;
      window.localStorage.setItem(this.offlineId, JSON.stringify(this.prj));
      this.offlineLabel = this.conf.messages.synchroniseButton;
      if (window.location.href.indexOf('?') !== -1) {
        if (window.location.href.indexOf('#') !== -1) {
          window.location.href = window.location.href.split('#')[0] + '&offline=true&fbId=' + this.offlineId + '#' + window.location.href.split('#')[1];
        } else {
          window.location.href = window.location.href + '&offline=true&fbId=' + this.offlineId;
        }
      } else {
        if (window.location.href.indexOf('#') !== -1) {
          window.location.href = window.location.href.split('#')[0] + '?offline=true&fbId=' + this.offlineId + '#' + window.location.href.split('#')[1];
        } else {
          window.location.href = window.location.href + '?offline=true&fbId=' + this.offlineId;
        }
      }
      console.log('window.location.href =========:: ' + window.location.href);
    } else {
      if (!this.prj.offline_history) {
        this.prj.offline_history = [];
      }
      this.prj.offline_history.push({date: new Date().getTime(), operation: 'synchronisation'});
      const operation = 'Save';
      this.eCaseHttpService.post('api/servlet/AngularServlet?sectionId=all_no_validation&operation=' + operation, this.prj).subscribe((res: any) => {
        // eslint-disable-next-line no-eval
        eval(res);
      });
    }
  }

  getTextLength(text): number {
    try {
      return text.length;
    } catch (e) {
      return 0;
    }
  }

  openValidationDialog(validationResult, validationLang = false): void {
    console.log('Inside the openValidationDialog');
    let groupedObject = _.groupBy(validationResult, 'sectionId');
    groupedObject = _.groupBy(validationResult, 'sectionId');
    for (const groupedItem in groupedObject) {
      if (groupedItem) {
        let replacingObject: any = {};
        validationResult.some((_item) => {
          if (_item.sectionId === groupedItem) {
            replacingObject = _item;
            return (_item.sectionId === groupedItem);
          }
        });
        const index = validationResult.indexOf(replacingObject);
        if (index > -1) {
          replacingObject.rowSpan = groupedObject[groupedItem].length;
          validationResult[index] = replacingObject;
        }
      }
    }
    this.errors = validationResult;
    if (!this.errors) {
      this.errors = [];
    }
    this.errors = this.errors.filter((thing, index) => index === this.errors.findIndex(obj => JSON.stringify(obj) === JSON.stringify(thing)));
    groupedObject = _.groupBy(this.errors, 'sectionId');
    for (const groupedItem in groupedObject) {
      if (groupedItem) {
        let replacingObject: any = {};
        this.errors.some((_item) => {
          if (_item.sectionId === groupedItem) {
            replacingObject = _item;
            return (_item.sectionId === groupedItem);
          }
        });
        const index = this.errors.indexOf(replacingObject);
        if (index > -1) {
          replacingObject.rowSpan = groupedObject[groupedItem].length;
          this.errors[index] = replacingObject;
        }
      }
    }
    this.errors = this.errors.sort((a, b) => (a.sectionIndex > b.sectionIndex) ? 1 : ((b.sectionIndex > a.sectionIndex) ? -1 : 0));
    const sectionIndexContainingErrors = this.errors.map(i => i.sectionIndex);
    for (let i = 0; i < this.globalComponent.sections.length; i++) {
      if (!((this.globalComponent.sections[i]).hide)) {
        (this.prj[this.globalComponent.sections[i].id]).fbValid = !sectionIndexContainingErrors.includes(i);
        (this.conf[this.globalComponent.sections[i].id])['fbValid'] = !sectionIndexContainingErrors.includes(i);
      }
    }
    console.log(this.errors);
    if (this.errors && this.errors.length > 0) {
      const dialogRef = this.matDialog.open(ValidationDialogComponent, {width: '800px',});
      const instance = dialogRef.componentInstance;
      instance.errors = this.errors.map((item) => {
        item['blockLabel'] = {};
        const block = this.globalComponent.conf[item.sectionId][item.componentName];
        if (block) {
          item['blockLabel'] = block.type === 'Table' ? block.tableLabel : block.label;
          if (item.subComponentName && item.subComponentName !== '') {
            const subBlock = this.globalComponent.conf[item.sectionId][item.componentName][item.subComponentName];
            if (subBlock) {
              item['blockLabel'] = subBlock.label;
            }
          }
          if (block.type === 'Checkbox' && block.label && block.label[this.lang].trim() === '') {
            item['blockLabel'] = ((this.globalComponent.prj[item.sectionId][item.componentName].value[0]
              && this.globalComponent.prj[item.sectionId][item.componentName].value[0].option) ? this.globalComponent
              .prj[item.sectionId][item.componentName].value[0].option.label : '');
          }
        }
        return item;
      });
      if (validationLang) {
        instance.lang = validationLang;
      } else {
        instance.lang = this.lang;
      }
      for (let i = 0; i < this.errors.length; i++) {
        if (this.errors[i].rowSpan > 1) {
          this.errors[i].isDuplicateSection = false;
        } else if (this.errors[i].rowSpan === 1) {
          if ((this.errors[i + 1]) && (this.errors[i + 1].sectionIndex === this.errors[i].sectionIndex)) {
            this.errors[i].isDuplicateSection = true;
            console.log('Next one is defined but sectionIndex is different');
          } else {
            this.errors[i].isDuplicateSection = (this.errors[i - 1]) && (this.errors[i - 1].sectionIndex === this.errors[i].sectionIndex);
          }
        }
      }
    }
  }

  openGeneralDialog(dialogData): void {
    console.log('Inside the openGeneralDialog function: ');
    console.log(dialogData);
    this.errors = dialogData;
    const dialogRef = this.matDialog.open(GeneralDialogComponent, {width: '800px',});
    const instance = dialogRef.componentInstance;
    instance.errors = this.errors;
    instance.lang = this.lang;
  }


  isBelongToGroup(section: any): any {
    const filteredSection = this.sections.filter(e => !e.hide && e.groupedSections && e.groupedSections.includes(section.id));
    const isBelongToGroup = filteredSection.length > 0;
    const obj: any = {};
    obj.isBelongToGroup = isBelongToGroup;
    if (isBelongToGroup) {
      // const hiddenSections = this.sections.filter(e => e.hide).map(e => e.id);
      const groupedSections = filteredSection.map(e => e.groupedSections)[0];
      //groupedSections = groupedSections.filter(id => !hiddenSections.includes(id));
      const indexInGroup = isBelongToGroup ? filteredSection.map(e => e.groupedSections && e.groupedSections.indexOf(section.id))[0] : -1;
      obj.isAtFirstPostionInGroup = indexInGroup === 0;
      obj.isAtLastPostionInGroup = indexInGroup === (groupedSections.length - 1);
    } else {
      obj.isAtFirstPostionInGroup = false;
      obj.isAtLastPostionInGroup = false;
    }
    section.groupItemObj = obj;
    return section;
  }

  beforSaveActions(): void {
    const globalComponent = this;
    try {
      for (let idx = 0; idx < this.globalComponent.sections.length; idx++) {
        if (this.sections[idx].id === this.selectedSection) {
          const isFirstTime = false;
          const globalPrj = this.prj;
          const globalConf = this.conf;
          let timer;
          // eslint-disable-next-line no-eval
          eval(this.sections[idx].beforeSave);
          this.prj = globalPrj;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  afterSaveActions(): void {
    try {
      this.sections.forEach((section, idx) => {
        if (this.sections[idx].id === this.selectedSection) {
          const globalPrj = this.prj;
          const globalComponent = this.globalComponent;
          // eslint-disable-next-line no-eval
          eval(this.sections[idx].afterSave);
          this.prj = globalPrj;
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  removeKeyFormJSON(obj, propToDelete): void {
    for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] === 'object') {
          this.removeKeyFormJSON(obj[property], propToDelete);
        } else {
          if (property === propToDelete) {
            delete obj[property];
          }
        }
      }
    }
  }

  saveDialogBefore(operation, submitButtonConfChange?, isFormRevisionSave?: boolean): void {
    console.log(this.previousSection);
    console.log("ICIII LA VALL")
    console.log(this.formJSON)
    console.log(this.globalPrj)
    this.isValidateButtonClicked = operation.toLowerCase().includes('validate');
    if (isFormRevisionSave) {
      this.isRevisionSaveButtonClicked = operation.toLowerCase().includes('save');
    } else {
      this.isSaveButtonClicked = operation.toLowerCase().includes('save');
    }
    if (this.previousSection.isShowDialogBeforeSaving) {
      let messageObject;
      // eslint-disable-next-line no-eval
      eval(this.previousSection.sectionJS);
      if (messageObject) {
        const dialogRef = this.matDialog.open(DialogComponent, {
          width: '600px',
          data: {dialog: messageObject, selectedLanguage: this.lang, showConfirmOption: true}
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.save(operation, submitButtonConfChange, isFormRevisionSave);
          }
        });
      } else {
        this.save(operation, submitButtonConfChange, isFormRevisionSave);
      }
    } else {
      this.save(operation, submitButtonConfChange, isFormRevisionSave);
    }
  }

  save(operation, submitButtonConfChange?, isFormRevisionSave?: boolean): void {
    console.log(operation);
    console.log('Inside the save function');
    this.lastServerAccess = (new Date()).getTime();
    this.iterate(this.prj);
    /*if (operation === 'Validate') {
      this.iterate(this.prj);
    }*/
    Object.keys(this.prj).filter(key => key !== 'formData' && this.prj[key] instanceof Object && this.prj[key] !== null).forEach((key) => {
      if (!this.prj[key].version_id) {
        this.prj[key].version_id = 1;
      }
    });
    let globalPrj = this.prj;
    const globalConf = this.conf;
    const globalComponent: any = this;
    globalComponent.pristineJson = _.cloneDeep(globalComponent.prj);
    if (operation === 'Save' || operation === 'Save and continue' || operation === 'Save Without Validation') {
      this.beforSaveActions();
    }
    let response;
    if (this.offline) {
      window.localStorage.setItem(this.prj.id, JSON.stringify(globalPrj));
      globalComponent.globalMessage.showMessages = true;
      globalComponent.globalMessage.invalidForm = false;
      globalComponent.globalMessage.title = globalConf.messages.saveSuccessOffline;
      this.saveOutputEvent.emit(this.prj);
    } else {
      globalComponent.sections.forEach((section, i) => {
        globalComponent.prj[globalComponent.sections[i].id].hide = (globalComponent.sections[i]).hide;
      });
      globalComponent.removeKeyFormJSON(globalComponent.prj, 'bphChange');
      this.prj = globalComponent.prj;
      globalPrj = globalComponent.prj;
      globalComponent.selectedSection = this.selectedSection;
      globalComponent.globalMessage = {};
      if(!this.prj.cpmCompetitionID && this.prj.competitionIds.length === 1) {
        this.prj.cpmCompetitionID = this.prj.competitionIds[0];
      }
      this.eCaseHttpService.post('api/servlet/AngularServlet?sectionId=' + this.selectedSection + '&operation=' + operation, this.prj).subscribe((res: any) => {
        response = res;
        if (response.status) {
          if (operation !== 'Save Without Validation') {
            try {
              const isValidateOnSave = this.parametersService.parameter['/core/ui/form/validate_on_save/enabled'] === 'true';
              if (operation === 'Validate' || (operation !== 'Validate' && isValidateOnSave)) {
                // eslint-disable-next-line no-eval
                eval(res.validationJs);
                this.refreshDataService.formValidation(this.prj);
              }
            } catch (e) {
              console.error(e);
            }
          }
          if (operation === 'Validate') {
            (globalComponent.sections[globalComponent.selectedSectionIndex]).showValidatorIcons = true;
            if (response.validationStatus) {
              if (globalComponent.selectedSection === 'all') {
                for (const property in response.prj) {
                  if (response.prj.hasOwnProperty(property)) {
                    if (typeof response.prj[property] === 'object') {
                      response.prj[property].fbValid = true;
                    }
                  }
                }
              } else {
                if (response.prj[globalComponent.selectedSection]) {
                  response.prj[globalComponent.selectedSection].fbValid = true;
                }
              }
              (globalComponent.sections[globalComponent.selectedSectionIndex]).isValid = true;
              this.conf[globalComponent.sections[globalComponent.selectedSectionIndex].id].fbValid = true;
              if (!submitButtonConfChange || !submitButtonConfChange.isHideSnackBar) {
                if (this.conf[globalComponent.sections[globalComponent.selectedSectionIndex].id].customSuccessValidationMessage) {
                  globalComponent.showSnackBar(true, this.conf[globalComponent.sections[globalComponent.selectedSectionIndex].id].customSuccessValidationMessage[this.lang]);
                } else {
                  if (!submitButtonConfChange || !submitButtonConfChange.noValidationMessage) {
                    if (this.pendingChangesGuard.isPristine) {
                      globalComponent.showSnackBar(true, this.getTranslatedValue('ecase.common.validationsuccessfulwithpristineform'));
                    } else {
                      globalComponent.showSnackBar(true, this.getTranslatedValue('ecase.common.validationsuccessfulwithoutpristineform'));
                    }
                  }
                }
              }
            } else {
              if (response.prj[globalComponent.selectedSection]) {
                response.prj[globalComponent.selectedSection].fbValid = false;
              }
              (globalComponent.sections[globalComponent.selectedSectionIndex]).isValid = false;
              this.conf[globalComponent.sections[globalComponent.selectedSectionIndex].id].fbValid = false;
              if (!submitButtonConfChange || !submitButtonConfChange.isHideSnackBar) {
                if (this.conf[globalComponent.sections[globalComponent.selectedSectionIndex].id].customFailureValidationMessage) {
                  globalComponent.showSnackBar(true, this.conf[globalComponent.sections[globalComponent.selectedSectionIndex].id].customFailureValidationMessage[this.lang]);
                } else {
                  if (this.pendingChangesGuard.isPristine) {
                    globalComponent.showSnackBar(false, this.getTranslatedValue('ecase.common.validationfailedwithpristineform'));
                  } else {
                    globalComponent.showSnackBar(false, this.getTranslatedValue('ecase.common.validationfailedwithoutpristineform'));
                  }
                }
              }
            }
            globalComponent.prj = _.merge(globalComponent.prj, response.prj);
            // globalComponent.pristineJson = globalComponent.prj;
            globalPrj = globalComponent.prj;
            (this.conf[globalComponent.selectedSection])['fbValid'] = globalPrj[globalComponent.selectedSection].fbValid;
            const saveParameters = this.params;
            const tempPrj = globalComponent.getCleanedJson(_.cloneDeep(globalPrj), true);
            saveParameters.initialQuestionForm = tempPrj;
            saveParameters.otherForm = tempPrj;
            saveParameters.selectedSection = globalComponent.selectedSection;
            saveParameters.isFormForConsent = this.projectService.isFormForConsent;
            this.executeSaveUrl(globalComponent, saveParameters, operation, globalPrj, submitButtonConfChange, isFormRevisionSave);
          }
        }
        globalComponent.globalMessage.showMessages = true;

        if (operation === 'Save' || operation === 'Save and continue' || operation === 'Save Without Validation') {
          console.log('operation is save now');
          for (const i of globalComponent.sections) {
            if (i.id === globalComponent.selectedSection) {
              globalComponent.selectedSectionIndex = globalComponent.sections.indexOf(i);
            }
          }
          const saveParameters = this.params;
          const tempPrj = globalComponent.getCleanedJson(_.cloneDeep(globalPrj), true);
          saveParameters.initialQuestionForm = tempPrj;
          saveParameters.otherForm = tempPrj;
          saveParameters.selectedSection = globalComponent.selectedSection;
          saveParameters.isFormForConsent = this.projectService.isFormForConsent;
          this.executeSaveUrl(globalComponent, saveParameters, operation, globalPrj, submitButtonConfChange, isFormRevisionSave);
          if (operation === 'Save and continue') {
            const scrollBar = document.querySelector('#synto-content-wrapper');
            document.body.style.overflow = 'hidden';
            scrollBar.scrollTop = 0;
          }
        }
        return response;
      });
    }
  }

  executeSaveUrl(globalComponent, saveParameters, operation, globalPrj, submitButtonConfChange?, isFormRevisionSave?: boolean): void {
    let url = _.cloneDeep(this.saveUrl);
    if (this.isFormRevisionMode && !!isFormRevisionSave) {
      url = _.cloneDeep(this.publishSaveUrl);
    }
    this.emptyPrj =  globalPrj;
    this.globalComponent.emptyPrj =  globalPrj;
    globalComponent.http.post(url, saveParameters).subscribe({
      next: (_response: any) => {
        if (_response.status) {
          if (this.isFormRevisionMode) {
            this.isFormRevisionSyncPending = !isFormRevisionSave;
          }
          if (_response.prj) {
            this.prj = _response.prj;
            this.globalPrj = _response.prj;
            globalPrj = _response.prj;
            globalComponent.prj = _response.prj;
          }
          if (operation !== 'Validate') {
            globalComponent.pristineJson = _.cloneDeep(globalComponent.prj);
            this.pendingChangesGuard.isPristine = true;
          }
          if (operation === 'Save and continue') {
            if (this.prj.preventSectionContinue === 'sectionInvalid') {
              alert(ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.preventSaveAndContinueValidationMsg', this.lang));
            } else {
              let index = parseInt(globalComponent.selectedSectionIndex, 10);
              globalComponent.sections.forEach((section, i) => {
                (globalComponent.sections[i]).hide =this.prj[globalComponent.sections[i].id].hide;
              });
              for (let i = index + 1; i < globalComponent.sections.length; i++) {
                if (!((globalComponent.sections[i]).hide) && !((globalComponent.sections[i]).isCategory)) {
                  index = i;
                  break;
                }
              }
              globalComponent.indexSelected = index;
              globalComponent.sectionClicked(globalComponent.sections[index], index, true);
            }
          }
          if (operation !== 'Validate' ) {
            globalComponent.executeAllActionInterval = setInterval(() => {
              const oldValues = globalComponent.oldValues;
              const sectionID = globalComponent.selectedSection;
              try {
                // eslint-disable-next-line no-eval
                eval(this.actions[sectionID]);
              } catch (e) {
                console.error(e);
              }
            }, 200);

            if( !submitButtonConfChange || !(submitButtonConfChange.isHideSnackBar ?? true)){
              globalComponent.showSnackBar(true, this.getTranslatedValue('ecase.common.saved'));
            }
          }
          try {
            globalComponent.prj.upload_to_be_deleted = globalComponent.prj.upload_to_be_deleted ? globalComponent.prj.upload_to_be_deleted : [];
          } catch (e) {
          }
          if (submitButtonConfChange) {
            const obj = {
              'prj': this.prj,
              'name': submitButtonConfChange.name
            };
            this.refreshDataService.submitForm(obj);
          }
        } else {
          if (_response.errorMessage.includes('Form data not synced')) {
            this.dialogService.openDialog(this.getTranslatedValue('ecase.common.formdataconflictdetected')).subscribe((decision) => {
              if (decision) {
                saveParameters.isOverridePrj = true;
                saveParameters.isFormForConsent = this.projectService.isFormForConsent;
                this.executeSaveUrl(globalComponent, saveParameters, operation, globalPrj, submitButtonConfChange);
              } else {
                // Do Something
              }
            });
          } else {
            console.log('ARARARAGAGAGAGAGAG');
            globalComponent.showSnackBar(false, this.getTranslatedValue(_response.errorMessage));
          }
        }
        this.afterSaveActions();
        this.isSaveButtonClicked = false;
        this.isRevisionSaveButtonClicked = false;
        this.isValidateButtonClicked = false;
        if (this.isFormRevisionMode) {
          this.prj.isRevisionFormSynced = !!isFormRevisionSave;
        }
        this.saveOutputEvent.emit(this.prj);
      },
      error: () => {
        this.isSaveButtonClicked = false;
        this.isRevisionSaveButtonClicked = false;
        this.isValidateButtonClicked = false;
      }
    });
  }


  useOrderDateReplaceRegex(date): string {
    const pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    return date.replace(pattern, '$3$2$1');
  }


  getOutput(label, lang, i): string {
    let r = '';
    try {
      // eslint-disable-next-line no-eval
      r = eval('return this.prj.' + label);
    } catch (e) {
    }
    return r;
  }

  resetSchedulerTable(blockData): void {
    console.log('right here........');
    console.log('right here........1');
    const prj = this.prj;
    const conf = this.conf;
    const renewYearColumns = this.renewYearColumns;
    this.sections.forEach((section) => {
      Object.keys(conf[section.id]).forEach((key) => {
        const blockConf = conf[section.id][key];
        if (blockConf.type === 'SchedulerTable') {
          // eslint-disable-next-line no-eval
          let tempVariable = eval(blockConf.multiYearPath);
          tempVariable = tempVariable ? tempVariable : 12;
          ((prj[section.id])[(conf[section.id][key]).name]).monthArray = Array.from(Array(Number(tempVariable)).keys());
        }
      });
    });
  }

  deleteFile(fileUploaded): void {
    if (!this.prj.upload_to_be_deleted) {
      this.prj.upload_to_be_deleted = [];
    }
    this.prj.upload_to_be_deleted.push(fileUploaded.id);
    fileUploaded.fileUploaded = false;
    fileUploaded.fileName = '';
  }

  mathFloor(value): number {
    return Math.floor(value);
  }

  parseInt(value): number {
    return parseInt(value, 10);
  }

  multiYearRowIndexCalculator(rowIndex, noOfYears): number {
    noOfYears = isNaN(noOfYears) ? 1 : noOfYears;
    return rowIndex % (noOfYears + 1);
  }

  getHeaderRowIndex(rowIndex, noOfYears): number {
    noOfYears = isNaN(noOfYears) ? 1 : noOfYears;
    return (noOfYears + 1) * (Math.floor(rowIndex / (noOfYears + 1)));
  }

  doNothing(): void {

  }


  executeFunction(event, confChange, bphChange): void {
    this.conf.refreshDataService = this.refreshDataService;
    if (confChange.focusInFunctions) {
      for (const functionName of confChange.focusInFunctions) {
        confChange[functionName](this.prj, this.conf, this.globalComponent);
      }
    }
    if (confChange.focusInEventFunctions) {
      for (const functionName of confChange.focusInEventFunctions) {
        confChange[functionName](event, this.prj, this.conf, this.globalComponent);
      }
    }
    this.emitOutputEvent.emit({
      event: event,
      prj: this.prj,
      conf: this.conf,
      globalComponent: this.globalComponent
    });
  }


  executeFunction2(event, confChange, bphChange): void {
    if (confChange.valueChangeFunctions) {
      for (const functionName of confChange.valueChangeFunctions) {
        confChange[functionName](this.prj, this.conf, this.globalComponent);
      }
    }
    this.emitOutputEvent.emit({
      event: event,
      prj: this.prj,
      conf: this.conf,
      globalComponent: this.globalComponent
    });
  }

  deleteTableRow(blockData, blockConf, i): void {
    this.globalPrj.isConfirmationSaved = false;
    if (blockConf.subBlocks) {
      blockConf.subBlocks.forEach((block, ii) => {
        if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
          const subBlockName = blockConf.subBlocks[ii].name;
          if (blockData.rows[i][subBlockName].value) {
            blockData.rows[i][subBlockName].value.forEach((e, iiTobd) => {
              if (blockData.rows[i][subBlockName].value[iiTobd] && blockData.rows[i][subBlockName].value[iiTobd].fileUploaded) {
                if (!this.globalPrj.upload_to_be_deleted) {
                  this.globalPrj.upload_to_be_deleted = [];
                }
                this.globalPrj.upload_to_be_deleted.push(blockData.rows[i][subBlockName].value[iiTobd].id);
              }
            });
          }
        }
      });
    }
    blockData.rows.splice(i, 1);
    (this.globalPrj[this.selectedSection])[blockConf.name] = blockData;
  }

  resetMultiYearTables(): void {
    const prj = this.prj;
    const conf = this.conf;
    const renewYearColumns = this.renewYearColumns;
    this.sections.forEach((section) => {
      Object.keys(conf[section.id]).forEach((key) => {
        console.log('running the method.....');
        const blockConf = conf[section.id][key];
        console.log(blockConf);
        if (blockConf.type === 'MultiYearTableT2') {
          if (blockConf.multiYearPath) {
            let duration = 1;
            if (!blockConf.isValueRanged) {
              // eslint-disable-next-line no-eval
              duration = Math.ceil((eval(blockConf.multiYearPath) / 12));
            } else {
              // eslint-disable-next-line no-eval
              const toDate = new Date(eval(blockConf.multiYearPath.split('-')[0].trim())).getFullYear();
              // eslint-disable-next-line no-eval
              const fromDate = new Date(eval(blockConf.multiYearPath.split('-')[1].trim())).getFullYear();
              duration = toDate - fromDate;
            }
            if (duration < 0) {
              duration = duration * -1;
            }
            if (blockConf.isValueRanged) {
              duration = duration + 1;
            }
            if (!isNaN(duration)) {
              blockConf.noOfYears = Math.abs(duration).toString();
              ((prj[section.id])[(conf[section.id][key]).name]).rows = [];
              ((prj[section.id])[(conf[section.id][key]).name]).multiYearPath = Math.abs(duration).toString();
              ((prj[section.id])[(conf[section.id][key]).name]).noOfYears = Math.abs(duration).toString();
              if (blockConf.type === 'SchedulerTable') {
                ((prj[section.id])[(conf[section.id][key]).name]).rowsForSchedulerTable = [];
                ((prj[section.id])[(conf[section.id][key]).name]).yearArray = Array.from(Array(Math.abs(duration)).keys());
              }
              if (blockConf.type === 'MultiYearTableT2') {
                ((prj[section.id])[(conf[section.id][key]).name]).yearArray = Array.from(Array(Math.abs(duration)).keys());
                renewYearColumns((prj[section.id])[(conf[section.id][key]).name], blockConf, conf);
              }
            }
          }
        }
      });
    });
  };

  renewYearColumns(blockData, blockConf, conf): void {
    const filteredLov = conf.lovs[blockConf.listName];
    for (let ii = 0; ii < filteredLov.list.length; ii++) {
      const aRow = {};
      blockData.multiYearPath = blockData.multiYearPath ? blockData.multiYearPath : blockConf.noOfYears;
      for (let jj = 0; jj < parseInt(blockData.multiYearPath, 10); jj++) {
        const entity = blockConf.subBlocks[0];
        (aRow[(ii.toString()) + (jj.toString())]) = {};
        (aRow[(ii.toString()) + (jj.toString())])[entity.name] = {};
        (aRow[(ii.toString()) + (jj.toString())])[entity.name].error = {};
        (aRow[(ii.toString()) + (jj.toString())])[entity.name].enableOutputEvent = jj !== 0;
      }
      blockData.rows.push(aRow);
    }
  };

  getMultiYearGlobalTotal(blockData, column, j, blockConf, currency, totalText): string {
    const rows = blockData || [];
    blockData.multiYearPath = blockData.multiYearPath ? blockData.multiYearPath : blockConf.noOfYears;
    const noOfYears = parseInt(blockData.multiYearPath, 10) || 1;
    if (totalText) {
      return totalText[this.lang];
    }
    let sum = 0;
    if (blockConf.type === 'MultiYearTableT2') {
      for (let i = 0; i < rows.length; i++) {
        let value = 0;
        if ((rows[i])[i.toString() + j.toString()] && (rows[i])[i.toString() + j.toString()][column] && !isNaN((rows[i])[i.toString() + j.toString()][column].value)) {
          value = Number((rows[i])[i.toString() + j.toString()][column].value);
        }
        sum = sum + value;
      }
    } else {
      if ((typeof column !== 'undefined') && column !== '') {
        for (let i = 0; i < rows.length; i++) {
          if (i % (noOfYears + 1) !== 0 || (i % (noOfYears + 1) === 0 && j === 0)) {
            let value = 0;
            if ((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()] &&
              (rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column] &&
              !isNaN((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column].value)) {
              value = Number((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column].value);
            }
            sum = sum + value;
          }
        }
      } else {
        sum = null;
      }
    }
    return this.eCaseNumberFormatterPipe.transform(sum, !!currency);
  }

  updateHeaderRowValue(blockConf, blockData, rowIndex, columnIndex, tableBlockData, noOfYears, currency): void {
    noOfYears = isNaN(parseInt(blockData.multiYearPath, 10)) ? 1 : parseInt(blockData.multiYearPath, 10);
    if (!isNaN(blockData.value)) {
      const rowHeaderIndex = this.getHeaderRowIndex(rowIndex, noOfYears);
      tableBlockData.rows = tableBlockData.rows || [];
      let sum = 0;
      for (let i = 1; i < (noOfYears + 1); i++) {
        let value = 0;
        if (tableBlockData && tableBlockData.rows && tableBlockData.rows[rowHeaderIndex + i]
          && (tableBlockData.rows[rowHeaderIndex + i])[i.toString() + columnIndex.toString()]
          && !isNaN((tableBlockData.rows[rowHeaderIndex + i])[i.toString() + columnIndex.toString()][blockConf.name].value)) {
          value = Number((tableBlockData.rows[rowHeaderIndex + i])[i.toString() + columnIndex.toString()][blockConf.name].value);
        }
        sum = sum + value;
      }
      let currencySign = '0,0$';
      if (!currency) {
        currencySign = '0,0';
      } else {
        currencySign = currency + '0,0';
      }
      (tableBlockData.rows[rowHeaderIndex])['0' + columnIndex.toString()].value = this.eCaseNumberFormatterPipe.transform(sum, !!currency);
    }
  }

  getMultiYearRowTotal(blockConf, rowIndex, tableBlockData, currency): string {
    tableBlockData.multiYearPath = tableBlockData.multiYearPath ? tableBlockData.multiYearPath : blockConf.noOfYears;
    const noOfYears = parseInt(tableBlockData.multiYearPath, 10) || 1;
    let currencySign = '0,0$';
    if (!blockConf.currency) {
      currencySign = '0,0';
    } else {
      currencySign = blockConf.currency + '0,0';
    }
    tableBlockData.rows = tableBlockData.rows || [];
    let sum = 0;
    if (blockConf.type === 'MultiYearTableT2') {
      for (let i = 0; i < noOfYears; i++) {
        let value = 0;
        if (tableBlockData && tableBlockData.rows && tableBlockData.rows[rowIndex]
          && (tableBlockData.rows[rowIndex])[rowIndex.toString() + i.toString()]
          && !isNaN((tableBlockData.rows[rowIndex])[rowIndex.toString() + i.toString()][blockConf.subBlocks[0].name].value)) {
          value = Number((tableBlockData.rows[rowIndex])[rowIndex.toString() + i.toString()][blockConf.subBlocks[0].name].value);
        }
        sum = sum + value;
      }
    } else {
      const calculatedRowIndex = this.multiYearRowIndexCalculator(rowIndex, noOfYears);
      for (let i = 0; i < blockConf.subBlocks.length; i++) {
        let value = 0;
        if (tableBlockData && tableBlockData.rows && tableBlockData.rows[rowIndex]
          && (tableBlockData.rows[rowIndex])[calculatedRowIndex.toString() + i.toString()]
          && !isNaN((tableBlockData.rows[rowIndex])[calculatedRowIndex.toString() + i.toString()][blockConf.subBlocks[i].name].value)) {
          value = Number((tableBlockData.rows[rowIndex])[calculatedRowIndex.toString() + i.toString()][blockConf.subBlocks[i].name].value);
        }
        sum = sum + value;
        if (rowIndex % (noOfYears + 1) === 0) {
          sum = sum + Number((tableBlockData.rows[rowIndex])['0' + i.toString()].value.replace(/,/g, '')
            .replace(/-/g, '0').replace(blockConf.currency ? blockConf.currency : '$', ''));
        }
      }
    }
    return this.eCaseNumberFormatterPipe.transform(sum, !!currency);
  }

  getMultiYearTotalGlobalTotal(tableBlockData, blockConf): string {
    const rows = tableBlockData.rows || [];
    blockConf.subBlocks = blockConf.subBlocks || [];
    tableBlockData.multiYearPath = tableBlockData.multiYearPath ? tableBlockData.multiYearPath : blockConf.noOfYears;
    const noOfYears = parseInt(tableBlockData.multiYearPath, 10) || 1;
    let sum = 0;
    if (blockConf.type === 'MultiYearTableT2') {
      for (let j = 0; j < noOfYears; j++) {
        const column = (blockConf.subBlocks[0]).name;
        if ((typeof column !== 'undefined') && column !== '') {
          for (let i = 0; i < rows.length; i++) {
            let value = 0;
            if ((rows[i])[i.toString() + j.toString()] && (rows[i])[i.toString() + j.toString()][column] && !isNaN((rows[i])[i.toString() + j.toString()][column].value)) {
              value = Number((rows[i])[i.toString() + j.toString()][column].value);
            }
            sum = sum + value;
          }
        } else {
          sum = null;
        }
      }
    } else {
      for (let j = 0; j < blockConf.subBlocks.length; j++) {
        const column = (blockConf.subBlocks[j]).name;
        if ((typeof column !== 'undefined') && column !== '') {
          for (let i = 0; i < rows.length; i++) {
            if (i % (noOfYears + 1) !== 0 || (i % (noOfYears + 1) === 0 && j === 0)) {
              let value = 0;
              if ((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()]
                && (rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column]
                && !isNaN((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column].value)) {
                value = Number((rows[i])[this.multiYearRowIndexCalculator(i, noOfYears).toString() + j.toString()][column].value);
              }
              sum = sum + value;
            }
          }
        } else {
          sum = null;
        }
      }
    }
    return this.eCaseNumberFormatterPipe.transform(sum, !!blockConf.currency);
  }

  deleteMultiYearRow(i, blockData, blockConf): void {
    blockData.multiYearPath = blockData.multiYearPath ? blockData.multiYearPath : blockConf.noOfYears;
    const noOfYears = isNaN(parseInt(blockData.multiYearPath, 10)) ? 1 : parseInt(blockData.multiYearPath, 10);
    const rowModulesIndex = (i % (noOfYears + 1)).toString();
    if (blockConf.subBlocks) {
      for (let ii = 0; ii < blockConf.subBlocks.length; ii++) {
        if (blockConf.subBlocks[ii] && blockConf.subBlocks[ii].type === 'upload') {
          const subBlockName = blockConf.subBlocks[ii].name;
          if (((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value
            && ((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value.fileUploaded === true) {
            if (!this.prj.upload_to_be_deleted) {
              this.prj.upload_to_be_deleted = [];
            }
            this.prj.upload_to_be_deleted.push(((blockData.rows[i])[rowModulesIndex + ii.toString()])[subBlockName].value.id);
          }
        }
      }
    }
    blockData.rows.splice(i, noOfYears + 1);
    blockData.rows.forEach((item, index) => {
      blockData.rows[index].rowIndex = index + 1;
    });
  }

  addMultiYearRow(blockData, blockConf): void {
    blockData.rows = blockData.rows || [];
    blockData.multiYearPath = blockData.multiYearPath ? blockData.multiYearPath : blockConf.noOfYears;
    const noOfYears = isNaN(parseInt(blockData.multiYearPath, 10)) ? 1 : parseInt(blockData.multiYearPath, 10);
    const parentRowIndex = blockData.rows.length > 0 ? (Math.max.apply(Math, blockData.rows.map(o => o.parentRowIndex ? o.parentRowIndex : 0)) + 1) : 1;
    for (let j = 0; j < (noOfYears + 1); j++) {
      const aRow: any = {};
      aRow.icon = 'remove_circle';
      aRow.isVisible = true;
      aRow.parentRowIndex = parentRowIndex;
      aRow.rowIndex = j === 0 ? blockData.rows.length + j + 1 : undefined;
      aRow.isParentRow = j === 0;
      aRow.yearIndex = j;
      aRow.uniqueRowIndex = blockData.rows.length > 0 ? (Math.max.apply(Math, blockData.rows.map(o => o.uniqueRowIndex ? o.uniqueRowIndex : 0)) + 1) : 1;
      let i = 0;
      blockConf.subBlocks.forEach((entity) => {
        aRow[(j.toString()) + (i.toString())] = {};
        aRow[(j.toString()) + (i.toString())].value = '-';
        aRow[(j.toString()) + (i.toString())].error = {};
        aRow[(j.toString()) + (i.toString())].showInputComponent = (i !== 0 && j === 0) || (i === 0 && j !== 0);
        if (i === 0 && j !== 0) {
          aRow[(j.toString()) + (i.toString())].value = blockConf.yearLabel[this.lang] + ' ' + j;
        }
        if (!blockConf[entity.name]) {
          blockConf[entity.name] = entity;
        }
        blockConf[entity.name].rowSpan = 1;
        if (blockConf[entity.name].isFirstColumnComponent) {
          blockConf[entity.name].showComponent = j === 0;
        } else {
          blockConf[entity.name].showComponent = j !== 0;
        }
        (aRow[(j.toString()) + (i.toString())])[entity.name] = {};
        (aRow[(j.toString()) + (i.toString())])[entity.name].error = {};
        (aRow[(j.toString()) + (i.toString())])[entity.name].enableOutputEvent = j !== 0;
        i++;
      });
      aRow.isHidden = false;
      aRow.rowDeleteButtonHide = j !== 0;
      blockData.rows.push(aRow);
      let ind = 1;
      blockData.rows.forEach((item, index) => {
        if (((noOfYears + 1) * (Math.floor(index / (noOfYears + 1)))) === index) {
          blockData.rows[index].rowIndex = ind;
          ind = ind + 1;
        } else {
          blockData.rows[index].rowIndex = undefined;
        }
      });
    }
  }

  toggleMultiYearRows(i, blockData, blockConf): void {
    blockData.multiYearPath = blockData.multiYearPath ? blockData.multiYearPath : blockConf.noOfYears;
    const noOfYears = parseInt(blockData.multiYearPath, 10) || 1;
    blockData.rows = blockData.rows || [];
    (blockData.rows[i]).isHidden = false;
    const rows = _.cloneDeep(blockData.rows);
    for (let j = 1; j < (noOfYears + 1); j++) {
      const row = _.cloneDeep(rows[i + j]);
      row.isHidden = rows[i].icon === 'remove_circle';
      rows[i + j] = _.cloneDeep(row);
    }
    rows[i].icon = rows[i].icon === 'remove_circle' ? 'add_circle' : 'remove_circle';
    blockData.rows = _.cloneDeep(rows);
  }

  getCircularReplacer(): any {
    const seen = new WeakSet();
    return (key, value): any => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  ngAfterViewInit(): void {
    if (window.location.pathname !== '/') {
      const globalComponent: any = this;
      globalComponent.cloneDeep = _.cloneDeep;
      globalComponent.http = this.eCaseHttpService;
      const globalPrj = this.prj;
      this.globalComponent.legendTop = '33px';
      if (!this.offline) {
        this.pristineStr = '';
        this.globalPrjStr = '';
        globalComponent.semaphoreAutosave = true;
        globalComponent.pristineJson = _.cloneDeep(globalComponent.getCleanedJson(globalComponent.prj));
      }
      this.globalComponent.showSaveNavBar = this.showSaveNavBar;
      console.log(this.globalReadOnlyMode);
      if (globalComponent.prj.paReportInternalId) {
        globalComponent.lastSectionIndex = globalComponent.indexSelected;
        globalComponent.indexSelected = globalComponent.lastSectionIndex;
        console.log('changing condition');
      }
      console.log('Salam x');
      this.sections = this.sections.map((section) => {
        section.templateContext = {};
        section.templateContext.sectionID = section.id;
        section.templateContext.blocks = [];
        // section.hide = this.prj[section.id].hide;
        for (const block in this.conf[section.id]) {
          if (block !== 'fbValid') {
            if (this.projectService.restrictedSections.length > 0) {
              section.isSectionRestricted = !this.projectService.restrictedSections.includes(section.id);
              this.conf[section.id][block].isReadOnlyField = this.conf[section.id][block].isReadOnlyField || !this.projectService.restrictedSections.includes(section.id);
              this.conf[section.id][block].subBlocks = this.conf[section.id][block].subBlocks || [];
              this.conf[section.id][block].subBlocks.forEach(subBlock => {
                subBlock.isReadOnlyField = subBlock.isReadOnlyField || !this.projectService.restrictedSections.includes(section.id);
              });
            }
            const ablock: any = {
              'templateContext': {
                'bphChange': this.prj[section.id][block],
                'confChange': this.conf[section.id][block],
                'selectedSectionId': section.id,
                'blockName': block,
                'isValidHtml': this.conf[section.id][block] && this.conf[section.id][block].hasOwnProperty('isValidHtml') ? this.conf[section.id][block].isValidHtml : true,
                'isValidTable': false,
                'isValidTableReadonly': false
              },
              'templateName': (this.conf[section.id][block]).type
            };
            section.templateContext.blocks.push(ablock);
          }
        }
        section = this.isBelongToGroup(section);
        return section;
      });
      if (this.sections.some(e => e.groupedSections && e.groupedSections.length > 0)) {
        globalComponent.updateGroupedSections = setInterval(() => {
          this.sections = this.sections.map(section => this.isBelongToGroup(section));
        }, 200);
      }
    }
  }

  ngDoCheck(): void {
    if (window.location.pathname !== '/') {
      const change = this.differ.diff(this.prj);
      if (change) {
        this.dataChanged.emit(this.prj);
      }
    }
  }

  ngOnDestroy(): void {
    const globalComponent: any = this;
    clearInterval(globalComponent.updateGroupedSections);
    if (globalComponent.executeAllActionInterval) {
      clearInterval(globalComponent.executeAllActionInterval);
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
    clearInterval(this.interval);
    if (this.subject) {
      this.subject.complete();
    }
    this.projectService.selectedForm = {};
    this.projectService.restrictedSections = [];
    this.projectService.isReadOnlyMode = false;
    this.projectService.changeSpinnerStatus(false);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
    if (this.subscription4) {
      this.subscription4.unsubscribe();
    }
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
    if (this.keysTobeRemoved && this.keysTobeRemoved.length > 0) {
      this.projectService.removeKeyListFromSession(this.keysTobeRemoved).subscribe(() => {
        this.projectService.removeKeyListFromSessionNoWindowID(this.keysTobeRemoved).subscribe(() => {
        });
      });
    }

    this.projectService.isNotPublishedForm = undefined;
    this.projectService.isFormForConsent = undefined;
  }

  addRow(blockData, blockConf): void {
    blockConf.showDetails = false;
    if (!blockConf.tableLabel) {
      blockData.globalShowDetail = false;
    }
    if (blockConf.readOnlyTable) {
      this.refreshDataService.toggleShowSaveNavBar(false);
    }

    blockData.rows = blockData.rows || [];
    const aRow: any = {};
    aRow.uniqueRowIndex = blockData.rows.length > 0 ? (Math.max.apply(Math, blockData.rows.map(o => o.uniqueRowIndex ? o.uniqueRowIndex : 0)) + 1) : 1;
    const globalArray = [];
    this.globalPrj = this.globalPrj ? this.globalPrj : _.cloneDeep(this.getCleanedJson(this.globalPrj, false));
    for (const x in this.globalPrj[this.sectionSelected]) {
      if (x !== blockConf.name) {
        globalArray.push(x);
      }
    }
    blockConf.subBlocks.forEach((entity) => {
      aRow[entity.name] = {};
      if (!blockConf[entity.name]) {
        blockConf[entity.name] = entity;
      }
      blockConf[entity.name].rowSpan = 1;
    });
    blockData.rows.push(aRow);
    blockData['selectedRowIndex'] = blockData.rows.length - 1;
    if (blockConf.readOnlyTable) {
      blockData.selectedRow = aRow;
      blockConf.showDetails = true;
      globalArray.forEach((e, k) => {
        try {
          ((this.globalPrj[this.selectedSection])[globalArray[k]]).globalShowDetail = false;
        } catch (_e) {
        }
      });
      blockConf.subBlocks.forEach((entity) => {
        aRow[entity.name].globalShowDetail = true;
      });
      window.scrollTo(0, 0);
    }
    if (blockConf.type !== 'SchedulerTable') {
      if (!blockConf.tableLabel[this.lang]) {
        console.log('no label');
        blockData.globalShowDetail = false;
      } else {
        blockData.globalShowDetail = true;
      }
    }

  }

  ngAfterContentInit(): void {
    if (window.location.pathname !== '/') {
      this.lastServerAccess = (new Date()).getTime();
      this.globalComponent.showLegend = false;
      this.offline = false;
      this.offlineId = '__FBID__';
      this.conf = {};
      this.sections = [];
      // this.showDetails = false;
      this.prj = {};
      let globalPrj = this.prj;
      this.conf.loggedInUserSsoUserId = this.loginAuthenticationService.userProfile.id;
      const globalConf = this.conf;
      const globalComponent: any = this;
      this.globalComponent = this;
      this.globalComponent.globalPrj = this.prj;
      this.globalComponent.lang = this.lang;
      console.log(globalConf);
      this.globalComponent.showHeaderAndSectionNavBar = this.showHeaderAndSectionNavBar;
      this.globalComponent.showSaveNavBar = this.showSaveNavBar;
      this.previousSection = {};
      this.conf.lovs = this.lovs;

      if (this.formJSON) {
        if (this.formJSON.extraConfVariables) {
          const restrictedKeys = ['sections', 'actions', 'rules', 'lovs'];
          Object.keys(this.formJSON.extraConfVariables).filter(key => !restrictedKeys.includes(key)).forEach(key => {
            this.conf[key] = this.formJSON.extraConfVariables[key];
          });
        }
        for (const section of this.formJSON.sections) {
          section.isSectionRestricted = this.projectService.restrictedSections.length > 0 && !this.projectService.restrictedSections.includes(section.id);
          this.conf[section.id] = {};
          this.prj[section.id] = {};
          this.prj[section.id].hide = section.hide;
          this.prj[section.id].sectionTitle = section.title;
          this.prj[section.id].sectionId = section.id;
          this.prj[section.id].hideValidateButtons = section.hideValidateButtons;
          this.prj[section.id].hideSaveButtons = section.hideSaveButtons;
          for (const block of section.blocks) {
            block.isReadOnlyField = block.isReadOnlyField || (this.projectService.restrictedSections.length > 0 && !this.projectService.restrictedSections.includes(section.id));
            (this.conf[section.id])[block.name] = block;
            (this.prj[section.id])[block.name] = {};
            ((this.prj[section.id])[block.name]).error = {};
            if (block.type === 'Radiobutton') {
              ((this.prj[section.id])[block.name]).value = {};
            } else if ((block.type === 'Checkbox' || block.type === 'upload')) {
              ((this.prj[section.id])[block.name]).value = [];
              ((this.prj[section.id])[block.name]).type = block.type;
            } else if (block.type === 'Table' || block.type === 'Accordion' || block.type === 'SimplifiedTable') {
              ((this.prj[section.id])[block.name]).value = '';
              ((this.prj[section.id])[block.name]).rows = [];
              ((this.conf[section.id])[block.name]).addRow = (bphChange, confChange): void => {
                console.log('sahil this is callled');
                this.refreshDataService.addNewTableRow({
                  'bphChange': bphChange,
                  'confChange': confChange,
                });
              };
            } else {
              ((this.prj[section.id])[block.name]).value = '';
            }
            ((this.prj[section.id])[block.name]).globalShowDetail = true;
            ((this.prj[section.id])[block.name]).globalReadOnlyMode = ((typeof (globalComponent.globalReadOnlyMode) === 'string') ? globalComponent
              .globalReadOnlyMode : ('"' + globalComponent.globalReadOnlyMode + '"'));
            for (const subBlock of block.subBlocks) {
              (((this.conf[section.id])[block.name])[subBlock.name]) = subBlock;
              console.log(subBlock.js);
              try {
                globalComponent.ECaseUtils = ECaseUtils;
                // eslint-disable-next-line no-eval
                eval(subBlock.js);
              } catch (e) {
                console.error(e);
              }
              (((this.prj[section.id])[block.name])[subBlock.name]) = {};
              (((this.prj[section.id])[block.name])[subBlock.name]).error = {};
              if (subBlock.type === 'Radiobutton') {
                (((this.prj[section.id])[block.name])[subBlock.name]).value = {};
              } else if ((subBlock.type === 'Checkbox' || subBlock.type === 'upload')) {
                (((this.prj[section.id])[block.name])[subBlock.name]).value = [];
                ((this.prj[section.id])[block.name]).type = block.type;
              } else if (subBlock.type === 'Table' || subBlock.type === 'Container' || subBlock.type === 'Accordion' || subBlock.type === 'SimplifiedTable') {
                this.prj[section.id][block.name][subBlock.name].value = '';
                this.prj[section.id][block.name][subBlock.name].rows = [];
                for (const subBlock2 of subBlock.subBlocks) {
                  subBlock2.isReadOnlyField = subBlock2.isReadOnlyField || (this.projectService.restrictedSections.length > 0 && !this.projectService.restrictedSections.includes(section.id));
                  this.conf[section.id][block.name][subBlock.name][subBlock2.name] = subBlock2;
                  console.log(subBlock2.js);
                  try {
                    globalComponent.ECaseUtils = ECaseUtils;
                    // eslint-disable-next-line no-eval
                    eval(subBlock2.js);
                  } catch (e) {
                    console.error(e);
                  }
                  this.prj[section.id][block.name][subBlock.name][subBlock2.name] = {};
                  this.prj[section.id][block.name][subBlock.name][subBlock2.name].error = {};
                  if (subBlock.type === 'Radiobutton') {
                    this.prj[section.id][block.name][subBlock.name][subBlock2.name].value = {};
                  } else if ((subBlock.type === 'Checkbox' || subBlock.type === 'upload')) {
                    this.prj[section.id][block.name][subBlock.name][subBlock2.name].value = [];
                    this.prj[section.id][block.name][subBlock.name][subBlock2.name].type = block.type;
                  } else if (subBlock.type === 'Table' || subBlock.type === 'SimplifiedTable') {
                    this.prj[section.id][block.name][subBlock.name][subBlock2.name].value = '';
                    this.prj[section.id][block.name][subBlock.name][subBlock2.name].rows = [];
                  } else {
                    this.prj[section.id][block.name][subBlock.name][subBlock2.name].value = '';
                  }
                  this.prj[section.id][block.name][subBlock.name][subBlock2.name].globalShowDetail = true;
                  this.prj[section.id][block.name][subBlock.name][subBlock2.name].globalReadOnlyMode = ((typeof (globalComponent.globalReadOnlyMode) === 'string') ? globalComponent
                    .globalReadOnlyMode : ('"' + globalComponent.globalReadOnlyMode + '"'));
                }
              } else {
                (((this.prj[section.id])[block.name])[subBlock.name]).value = '';
              }
              (((this.prj[section.id])[block.name])[subBlock.name]).globalShowDetail = true;
              (((this.prj[section.id])[block.name])[subBlock.name]).globalReadOnlyMode = ((typeof (globalComponent.globalReadOnlyMode) === 'string') ? globalComponent
                .globalReadOnlyMode : ('"' + globalComponent.globalReadOnlyMode + '"'));
            }
            console.log(block.js);
            try {
              globalComponent.ECaseUtils = ECaseUtils;
              // eslint-disable-next-line no-eval
              eval(block.js);
            } catch (e) {
              console.error(e);
            }
          }
          delete section.blocks;
          if (!section.integration) {
            section.integration = '';
          }
          if (!section.afterSave) {
            section.afterSave = '';
          }
          if (!section.beforeSave) {
            section.beforeSave = '';
          }
          if (!section.condition) {
            section.condition = '';
          }
          if (!section.isShowDialogBeforeSaving) {
            section.isShowDialogBeforeSaving = false;
          }
          if (!section.sectionJS) {
            section.sectionJS = '';
          }
          this.sections.push(section);
        }
      }
      console.log(this.conf);
      console.log(this.prj);
      this.globalComponent.globalPrj = this.prj;
      if (this.sections[this.selectedSectionIndex]) {
        this.sections[this.selectedSectionIndex].selected = true;
        this.previousSection = this.sections[this.selectedSectionIndex];
        this.selectedSection = this.sections[this.selectedSectionIndex].id;
        this.globalComponent.selectedSectionIndex = this.selectedSectionIndex;
        console.log('Iam there');
        console.log(this.globalComponent.selectedSectionIndex);
        try {
          // eslint-disable-next-line no-eval
          eval(this.sections[this.selectedSectionIndex].actions);
        } catch (e) {
        }
      }
      this.conf.messages = {
        'offlineButton': {
          'en': 'Work Offline',
          'fr': 'Travailler hors connexion'
        },
        'synchroniseButton': {
          'en': 'Synchronize',
          'fr': 'Synchroniser'
        },
        'changeSectionMessage': {
          'fr': 'Vous êtes sur le point de quitter la section du formulaire sans avoir sauvegardé les données que vous ' +
            'avez saisies. Si vous cliquez sur OK vous perdrez les données non sauvegardées.',
          'en': 'You are about to change the section without saving your data. If you click OK you will lose your unsaved data.'
        },
        'saveSuccessOffline': {
          'en': 'Saved successfully [Off line].',
          'fr': 'Sauvegarde réussie [Hors connexion].'
        },
        'legend': {
          'en': 'Legend',
          'fr': 'Légende'
        },
        'offlineMessage': {
          'fr': 'Vous allez quitter le mode connecté. Vous pourrez continuer à travailler sur ce dossier "hors connexion". ' +
            'Avant d\'éteindre votre ordinateur ou de fermer votre application, merci de : \n 1) cliquer sur "sauvegarder" \n 2) copier/coller dans un document ' +
            'texte l\'adresse qui apparaît dans la barre d\'adresse \n\n Pour pouvoir revenir dans votre dossier, une fois que vous aurez éteint votre ordinateur, ' +
            'merci de : \n 1) ouvrir le même navigateur (mozilla, chrome...)\n 2) copier/coller l\'adresse sauvegardée dans la barre d\'adresse',
          'en': 'You will soon be OFFLINE. You can continue to work OFF LINE on this sheet. Before closing your computer or close the application, ' +
            'please:\n\n 1) Click on "SAVE"\n 2) Copy/paste the address you can see in the address bar of your browser.\n\nIn order to work again on your sheet,' +
            ' once your computer is OFF, please:\n 1) Open the same internet navigator ( Mozilla, chrome …)\n 2) Copy/ paste the copied address in the address bar.'
        },
        'error': {
          'en': 'wError !',
          'fr': 'wErreur!'
        }
      };

      this.prj.isConfirmationSaved = false;
      this.offlineLabel = this.conf.messages.offlineButton;
      globalPrj = this.formData;
      this.emptyPrj = _.merge(_.cloneDeep(this.prj), globalPrj);
      console.log('Execute after save actions');
      const urlSectionId = this.parseInt(this.activatedRoute.snapshot.paramMap.get('sectionId'));
      const isSectionAndUrlSectionIdSame = (Number.isInteger(urlSectionId) && this.selectedSectionIndex === urlSectionId);

      this.iterate(globalPrj);
      if (!this.offline) {
        const nowTime = Date.now();
        globalComponent.prj = _.merge(_.cloneDeep(this.emptyPrj), globalPrj);
        globalComponent.http = this.eCaseHttpService;
        globalPrj = globalComponent.prj;
        console.log('globalPrj');
        console.log(globalPrj);
        if (!globalComponent.prj.id) {
          globalComponent.prj.id = globalComponent.offlineId;
        }
        const isFirstTime = false;
        let timer;
        globalComponent.sections.forEach((section, i) => {
          console.log(globalComponent.sections[i].integration);
          try {
            // eslint-disable-next-line no-eval
            eval('var lovs = ' + JSON.stringify(this.lovs) + ';' + ECaseUtils.replaceAllWithoutRegex(globalComponent.sections[i].integration, 'this.globalConf.lovs', 'lovs'));
            this.prj = globalPrj;
          } catch (e) {
            console.error(e);
          }
        });
        setTimeout(() => {
          globalComponent.pristineJson = _.cloneDeep(globalComponent.prj);
        }, 2000);
        globalComponent.pristineJson = _.cloneDeep(globalComponent.prj);
      } else {
        this.offlineLabel = this.conf.messages.synchroniseButton;
        globalPrj = JSON.parse(window.localStorage.getItem(this.offlineId));
        this.prj = globalPrj;
      }
      const oldValues = {};
      if (globalComponent.selectedSection) {
        globalComponent.executeAllActionInterval = setInterval(() => {
          const sectionID = globalComponent.selectedSection;
          try {
            // eslint-disable-next-line no-eval
            eval(this.actions[sectionID]);
          } catch (e) {
            console.error(e);
          }
        }, 200);
      }
      for (let i = 0; i < globalComponent.sections.length; i++) {
        if (!((globalComponent.sections[i]).hide)) {
          globalComponent.lastSectionIndex = i;
        }
      }
      this.lastSectionIndex = globalComponent.lastSectionIndex;
      this.projectService.changeSpinnerStatus(false);
      // here we call websocket to register the user among the users editing an application, I removed the condition as
      // it needs to be on all applications. on the future when other type of applications are added this may need to be
      // changed
      this.openWebsocket();
      for (const section of this.formJSON.sections) {
        (this.conf[section.id])['fbValid'] = this.prj[section.id].fbValid;
      }
      if (Number.isInteger(urlSectionId)) {
        if (urlSectionId <= globalComponent.sections.length && this.selectedSectionIndex !== urlSectionId) {
          console.log('VALID');
          const isSectionHidden = globalPrj[globalComponent.sections[urlSectionId].id];
          const sectionName = globalComponent.sections[urlSectionId].name;
          if (isSectionHidden.hide && isSectionHidden.hide === true) {
            globalComponent.sectionClicked(globalComponent.sections[0], 0, false);
            this.translate.get('ecase.common.noAccessToSectionErrorMsg').subscribe((data) => {
              console.log(data.replace('${sectionName}', sectionName));
              this.showSnackBar(false, (data.replace('${sectionName}', sectionName)));
            });
          } else {
            globalComponent.sectionClicked(globalComponent.sections[urlSectionId], urlSectionId, false);
          }
        }
      } else if (this.selectedSectionIndex !== undefined && this.selectedSectionIndex !== null && this.selectedSectionIndex !== 0) {
        this.sections[this.selectedSectionIndex].hide = false;
        globalComponent.sections[this.selectedSectionIndex].hide = false;
        globalComponent.sectionClicked(globalComponent.sections[this.selectedSectionIndex], this.selectedSectionIndex, false);
      } else if (!Number.isInteger(urlSectionId) || (Number.isInteger(urlSectionId) && urlSectionId >= globalComponent.sections.length)) {
        console.log('invalid-----');
        globalComponent.sectionClicked(globalComponent.sections[0], 0, false);
      }


      if (!this.projectService.formLangArray.includes(this.translate.getDefaultLang())) {
        this.prj.preferredLanguage = this.lang;
      }
    }
  }

  eventFromSubmitButton($event): void {
    this.emitOutputEvent.emit($event);
  }

  changeTitle(event): void {
    console.log('ttile changed :' + event);
    this.projectService.notifyWhenaTitleChanges(event);
  }

  openWebsocket(): void {
    if (!this.isFormbuilderModeEnabled) {
      console.log('websocket ...');
      this.eCaseHttpService.get('/api/getAllUsersOnSameApp').subscribe((data: InEvent[]) => {
        console.log('websocket');
        console.log(data);
        let users = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.webForm.ws.existingaccess');
        data = data.filter(entry => entry.username !== this.loginAuthenticationService.userProfile.email);
        for (const entry of data) {
          users = users + '\n' + entry.name;
        }
        let currentUrl = window.location.href;
        if (data.length > 0 && ( currentUrl.indexOf("rms-rf") == -1 && currentUrl.indexOf("rms-rmp") == -1)) {
          alert(users);
        }
      });
      const protocol = window.location.protocol.replace('http', 'ws').replace('https', 'wss');
      const host = window.location.host;
      this.subject = webSocket(protocol + '//' + host + '/api/websocket');
      console.log('websocket OBJECT : ');
      console.log(this.subject);
      this.subject.subscribe((msg) => {
        console.log('message received: ' + msg);
        console.log(msg);
        if (msg.eventType === '-') {
          console.log(msg.name + ' left <<<< ');
        } else {
          // this.loginAuthenticationService.userProfile.fname + ' ' + this.loginAuthenticationService.userProfile.lname;
          const currentLoggedInUserName = this.loginAuthenticationService.userProfile.email;
          const currURL = window.location.href;
          if (currentLoggedInUserName !== msg.username && currentLoggedInUserName !== '' && ( currURL.indexOf("rms-rf") == -1 && currURL.indexOf("rms-rmp") == -1)) {
            alert(ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.webForm.ws.userjoined') + '\n' + msg.name);
          }
          console.log(currentLoggedInUserName);
          console.log(msg.name + ' >>>  joined ');
        }
      }, err => console.log(err), () => console.log('complete'));
      this.interval = setInterval(() => {
        this.subject.next({id: this.formData.caseApplicationId, name: '', eventType: '*', time: 0});
      }, 110000);
    }
  }

  ngOnInit(): void {
    if (window.location.pathname !== '/') {
      const title = this.projectService.selectedForm ? ECaseUtils.textTruncate(this.projectService.selectedForm.title, 50) : '';
      let applicationBreadcrumb = this.projectService.selectedForm ? (this.projectService.selectedForm.applicationId ? title +
        ' (' + this.projectService.selectedForm.applicationId + ')' : title) : '';
      if (this.parametersService
        .parameter['/core/ui/eligibility_search_screen/show_application_id_internal_id/enabled'] === 'true' &&
        this.projectService.selectedForm.applicationInternalId &&
        this.projectService.selectedForm.applicationInternalId !== this.projectService.selectedForm.applicationId.toString()) {
          applicationBreadcrumb = this.projectService.selectedForm ? (this.projectService.selectedForm.applicationId ? title +
            ' ' + this.projectService.selectedForm.applicationId + ' (' + this.projectService.selectedForm.applicationInternalId + ')' : title) : '';
      }
      this.setSectionBreadcrumb.setSectionforBreadCrumb.next(applicationBreadcrumb);
      //  }

      this.titleService.setTitleWithSeparator([applicationBreadcrumb]);
      this.projectService.changeReviewerDashboardFlag(false);

      // this.lang = this.translate.getDefaultLang();
      this.lang = this.projectService.formLangArray.includes(this.translate.getDefaultLang()) ? this.translate.getDefaultLang() : this.projectService.formLangArray[0];

      let called = false;
      this.languageChangeSubscription = this.translate.onDefaultLangChange.subscribe((params: DefaultLangChangeEvent) => {
        if (this.projectService.formLangArray.includes(params.lang)) {
          this.lang = params.lang;
          called = false;
        } else {
          if (!called) {
            alert(ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.formnotavaialableinlagnaugeformlangwillrevert', this.lang));
          }
          called = true;
        }
      });
      this.subscription = this.refreshDataService.saveFormSubscriber().subscribe((data) => {
        console.log('saved called');
        if (data.prj) {
          this.prj = data.prj;
          this.globalPrj = data.prj;
          this.globalComponent.prj = data.prj;
        }
        this.beforSaveActions();
        if (typeof data.isSaveonSubmitEnabled === 'undefined' || data.isSaveonSubmitEnabled) {
          this.save(data.operation, data, false);
        } else {
          this.eCaseHttpService.post('api/servlet/AngularServlet?sectionId=all&operation=Validate', this.prj).subscribe((res: any) => {
            if (res.status) {
              const globalComponent: any = this;
              globalComponent.globalMessage = {};
              const globalPrj = this.prj;
              const globalConf = this.conf;
              try {
                // eslint-disable-next-line no-eval
                eval(res.validationJs);
              } catch (e) {
                console.error(e);
              }
              const obj = {
                'prj': this.prj,
                'name': data.name
              };
              this.refreshDataService.submitForm(obj);
            }
          });
        }
        this.afterSaveActions();
      });
      this.subscription2 = this.refreshDataService.onValidationError().subscribe((data) => {
        this.openValidationDialog(data, this.lang);
      });
      this.subscription3 = this.refreshDataService.toggleShowSaveNavBarEventSubscriber().subscribe((data) => {
        this.toggleShowSaveNavBar(data);
      });
      this.subscription4 = this.refreshDataService.onTableCancelClick().subscribe((data) => {
        this.globalPrj = data;
        this.prj = data;
        this.globalComponent.prj = data;
      });
    }
  }
}
